.hero {
  background-color: #2d2d2d;
  color: #e9e9e9;
  font-weight: 300;
  padding-top: 50px;
  padding-bottom: 30px;
  text-align: center;

  h1 {
    font-weight: 400;
    font-size: 64px;
  }

  p {
    text-transform: uppercase;
    font-size: 16px;
  }
}
