@import url("//fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,400italic");
@import './core/colors';

html {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, sans-serif;
}

a {
  color: $link-color;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $link-hover-color;
    text-decoration: underline;
  }
}

img {
  max-width: 100%;
}

code {
  color: #ffffff;
  background-color: $link-color;
  padding: 4px 4px;
  border-radius: 4px;
  font-size: 14px;
}

.container {
  display: block;
  margin: 0 auto;

  @media (min-width: 0px) {
    padding: 0 15px;
  }

  @media (min-width: 768px) {
    padding: 0;
    width: 750px;
  }

  @media (min-width: 1070px) {
    padding: 0;
    width: 975px;
  }

  @media (min-width: 1200px) {
    padding: 0;
    width: 1170px;
  }
}

