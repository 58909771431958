@import "//fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,400italic";
html {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, sans-serif;
}
a {
  color: #cc7a6f;
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  color: #cc7a6f;
  text-decoration: underline;
}
img {
  max-width: 100%;
}
code {
  color: #ffffff;
  background-color: #cc7a6f;
  padding: 4px 4px;
  border-radius: 4px;
  font-size: 14px;
}
.container {
  display: block;
  margin: 0 auto;
}
@media (min-width: 0px) {
  .container {
    padding: 0 15px;
  }
}
@media (min-width: 768px) {
  .container {
    padding: 0;
    width: 750px;
  }
}
@media (min-width: 1070px) {
  .container {
    padding: 0;
    width: 975px;
  }
}
@media (min-width: 1200px) {
  .container {
    padding: 0;
    width: 1170px;
  }
}
.buttonUnit {
  margin-top: 60px;
  text-align: center;
}
.buttonUnit a {
  margin: 12px;
}
.button {
  background: #cc7a6f;
  color: #fafafa;
  display: inline-block;
  border-radius: 4px;
  padding: 8px 16px;
  margin: 0 12px;
  text-decoration: none;
  font-size: 22px;
  font-weight: 400;
  cursor: pointer;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.3);
}
.button:hover {
  color: #fafafa;
  text-decoration: none;
}
.hero {
  background-color: #2d2d2d;
  color: #e9e9e9;
  font-weight: 300;
  padding-top: 50px;
  padding-bottom: 30px;
  text-align: center;
}
.hero h1 {
  font-weight: 400;
  font-size: 64px;
}
.hero p {
  text-transform: uppercase;
  font-size: 16px;
}
/*# sourceMappingURL=index.3ab95ff2.css.map */
